import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import commonAPIService from "../../common/js/common-api-service";
import Utility from "../../../shared/utility.js";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      selectedCountry: "",
      countryList: [],
      apiJSON: {
        user_id: parseInt(this.userId),
        pass: 0,
        CountryJSON: "",
      },
      newItemDialog: false,
      addCountry: false,
      newCountrySelected: false,
      addState: false,
      newStateSelected: false,
      countryName: "",
      countryCode2: "",
      countryCode3: "",
      countryPhoneCode: "",
      countryPhoneFormat: "",
      stateCountry: "",
      disableStateCountry: false,
      stateName: "",
      stateCode: "",
      totalRecords: 0,
      searchMaster: "",
      countryStateItems: [],
      countryOrState: "",
      countryDetails: [],
      showCountryDetails: false,
      stateEditDialog: false,
      editStateCountry: "",
      editStateName: "",
      editStateCode: "",
      countryStateHeaders: [
        { text: "State", value: "State", class: "primary customwhite--text", width: "40%" },
        { text: "State Code", value: "StateCode", class: "primary customwhite--text", width: "40%" },
        { text: "Action", value: "action", class: "primary customwhite--text", width: "20%" },
      ],
      max2Rule: [(v) => /^[a-zA-Z0-9]{2}$/.test(v) || "Only two letters are allowed", (v) => !!v || "Field is required"],
      max3Rule: [(v) => /^[a-zA-Z0-9]{3}$/.test(v) || "Only three letters are allowed", (v) => !!v || "Field is required"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getCountryData();
  },
  mounted() {},
  methods: {
     // to make sure user can only add number value
     isNumber(event){
       let value= Utility.isNumber(event)
       return value
     },
    //Reset Function
    resetFunction() {
      this.selectedCountry = "";
      this.countryList = [];
      this.apiJSON = {
        user_id: 0,
        pass: 0,
        CountryJSON: "",
      };
      this.newItemDialog = false;
      this.totalRecords = 0;
      this.searchMaster = "";
      this.countryStateItems = [];
      this.countryDetails = [];
      this.showCountryDetails = false;
      this.stateEditDialog = false;
      this.getCountryData();
    },
    //Get Country data
    async getCountryData() {
      this.apiJSON.user_id = parseInt(this.userId);
      this.apiJSON.pass = 1;
      this.apiJSON.CountryJSON = JSON.stringify({
        country_id: 0,
        country_name: "",
        country_code2: "",
        country_code3: "",
        phone_format: "",
        state_code: "",
        state_name: "",
      });
      let countryData = await commonAPIService.countryStateMaster("post", this.apiJSON);
      this.countryList = countryData;
    },
    //Country Selected
    async countryChange() {
      if (this.selectedCountry !== null) {
        this.apiJSON.user_id = parseInt(this.userId);
        this.apiJSON.pass = 2;
        this.apiJSON.CountryJSON = JSON.stringify({
          country_id: parseInt(this.selectedCountry),
          country_name: "",
          country_code2: "",
          country_code3: "",
          phone_format: "",
          phone_code: "",
          state_code: "",
          state_name: "",
        });
        let stateData = await commonAPIService.countryStateMaster("post", this.apiJSON);
        if (stateData.message !== "NA") {
          this.countryStateItems = stateData;
          this.totalRecords = stateData.length;
          this.countryDetails = [];
          for (const item of this.countryList) {
            if (item.CountryId == this.selectedCountry) {
              this.countryDetails.push(item);
            }
          }
          this.showCountryDetails = true;
        } else {
          this.countryStateItems = [];
          this.totalRecords = 0;
          this.countryDetails = [];
          this.showCountryDetails = false;
          for (const item of this.countryList) {
            if (item.CountryId == this.selectedCountry) {
              this.countryDetails.push(item);
            }
          }
          let Alert = {
            type: "error",
            isShow: true,
            message: "There are no States available for this Country",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Popup close
    onCancel() {
      if (this.newItemDialog) {
        this.addCountry = false;
        this.newCountrySelected = false;
        this.addState = false;
        this.newStateSelected = false;
        this.countryOrState = "";
        if (this.addCountry) {
          this.countryName = "";
          this.countryCode2 = "";
          this.countryCode3 = "";
          this.countryPhoneCode = "";
          this.countryPhoneFormat = "";
        } else if (this.addState) {
          this.stateCountry = "";
          this.disableStateCountry = false;
          this.stateName = "";
          this.stateCode = "";
        }
        this.newItemDialog = false;
      } else if (this.stateEditDialog) {
        this.editStateCountry = "";
        this.editStateName = "";
        this.editStateCode = "";
        this.stateEditDialog = false;
      }
    },
    //New Country selected
    newCountry() {
      this.newCountrySelected = true;
      this.newStateSelected = true;
      this.countryName = "";
      this.countryCode2 = "";
      this.countryCode3 = "";
      this.countryPhoneCode = "";
      this.countryPhoneFormat = "";
      this.countryOrState = "Country";
    },
    //New Country add call
    clickAddCountry() {
      if (this.$refs.countryForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.apiJSON.user_id = parseInt(this.userId);
        this.apiJSON.pass = 3;
        this.apiJSON.CountryJSON = JSON.stringify({
          country_id: 0,
          country_name: this.countryName,
          country_code2: this.countryCode2,
          country_code3: this.countryCode3,
          phone_format: this.countryPhoneFormat,
          phone_code: this.countryPhoneCode,
          state_code: "",
          state_name: "",
        });
        this.axios
          .post("/co/country_state_master", this.apiJSON)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.onCancel();
              this.getCountryData();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
    //New State Selected
    newState() {
      this.newCountrySelected = true;
      this.newStateSelected = true;
      this.stateCountry = "";
      this.disableStateCountry = false;
      this.stateName = "";
      this.stateCode = "";
      this.countryOrState = "State";
    },
    //New State add call
    clickAddState() {
      if (this.$refs.stateForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.apiJSON.user_id = parseInt(this.userId);
        this.apiJSON.pass = 4;
        this.apiJSON.CountryJSON = JSON.stringify({
          country_id: this.countryDetails[0].CountryId,
          country_name: this.countryDetails[0].Country,
          country_code2: this.countryDetails[0].code_2,
          country_code3: this.countryDetails[0].code_3,
          phone_format: this.countryDetails[0].phone_format,
          phone_code: this.countryDetails[0].phone_code,
          state_code: this.stateCode,
          state_name: this.stateName,
        });
        this.axios
          .post("/co/country_state_master", this.apiJSON)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.onCancel();
              this.countryChange();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
    //Country in State popup selected
    stateCountrySelected() {
      if (this.stateCountry !== null && this.stateCountry !== undefined) {
        this.disableStateCountry = true;
        this.stateName = "";
        this.stateCode = "";
      }
    },
    //Refresh Functionality for the popup
    refreshDialog() {
      this.addCountry = false;
      this.newCountrySelected = false;
      this.addState = false;
      this.newStateSelected = false;
      this.countryOrState = "";
      if (this.addCountry) {
        this.countryName = "";
        this.countryCode2 = "";
        this.countryCode3 = "";
        this.countryPhoneCode = "";
        this.countryPhoneFormat = "";
      } else if (this.addState) {
        this.stateCountry = "";
        this.disableStateCountry = false;
        this.stateName = "";
        this.stateCode = "";
      }
    },
    //Edit State Details
    editState(item) {
      this.editStateCountry = this.countryDetails[0].CountryId;
      this.editStateName = item.State;
      this.editStateCode = item.StateCode;
      this.stateEditDialog = true;
    },
    //Edit State button click
    clickEditState() {
      if (this.$refs.stateEditForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.apiJSON.user_id = parseInt(this.userId);
        this.apiJSON.pass = 5;
        this.apiJSON.CountryJSON = JSON.stringify({
          country_id: this.countryDetails[0].CountryId,
          country_name: this.countryDetails[0].Country,
          country_code2: this.countryDetails[0].code_2,
          country_code3: this.countryDetails[0].code_3,
          phone_format: this.countryDetails[0].phone_format,
          phone_code: this.countryDetails[0].phone_code,
          state_code: this.editStateCode,
          state_name: this.editStateName,
        });
        this.axios
          .post("/co/country_state_master", this.apiJSON)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.onCancel();
              this.countryChange();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
    //Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
